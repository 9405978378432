export const STAR_RATING_MIN_DISPLAY = 3;
export const STAR_RATING_SHOULD_ROUND_UP = true;

// round up or round off to half step for star component display
export const roundStarRating = (
  value: number,
  config?: {
    minDisplay?: number | null; // passing null disables minimum check
    shouldRoundUp?: boolean;
  }
): number | null => {
  const minDisplay =
    config?.minDisplay !== undefined ? config?.minDisplay : STAR_RATING_MIN_DISPLAY;
  const shouldRoundUp = config?.shouldRoundUp ?? STAR_RATING_SHOULD_ROUND_UP;

  if (typeof value !== 'number') return null;

  // if minDisplay is set and if reaches the minimum, will display rating, else will not
  if (typeof minDisplay === 'number' && value < minDisplay) return null;

  // round up to next half-star, e.g. 2.1 -> 2.5 and 2.7 -> 3.0
  if (shouldRoundUp) return Math.ceil(value * 2) / 2;

  // round off to next half-star, e.g. 2.1 -> 2.0 and 2.7 -> 2.5
  return Math.round(value * 2) / 2;
};

// round up to 1 decimal places
export const roundAverageRating = (value: number, hideZeroDecimal?: boolean): string => {
  if (typeof value !== 'number') return null;

  // e.g. 3.11 -> 3.2, 3.96 -> 4.0
  const roundedUp = Math.ceil(value * 10) / 10;

  if (hideZeroDecimal) return `${roundedUp}`;

  // enforce 3 -> 3.0
  return roundedUp.toFixed(1);
};
