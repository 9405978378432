import { round } from './roundPrice';

export const categoriesWithLocationTextOverride = [
  'stay-at-home-experiences',
  'collection-vouchers',
  'gift-cards',
  'physical-gifts',
];

export const formatLocations = (locations: { name: string }[]) => {
  if (!locations?.length) return null;

  if (locations?.length > 1) return formatNoOfLocations(locations.length);
  return locations?.[0]?.name || '';
};

export const formatNoOfLocations = (noOfLocations: number) => {
  if (!noOfLocations || isNaN(noOfLocations)) return null;

  if (noOfLocations > 1) return `${noOfLocations} locations`;
  return `${noOfLocations} location`;
};

export const getProductCategoriesWithLocationTextOverride = (categories: string[]) => {
  if (!Array.isArray(categories)) return [];
  return categories.filter((category) => categoriesWithLocationTextOverride.includes(category));
};

export const formatLocationTextOverride = (categories: string[]) => {
  if (!getProductCategoriesWithLocationTextOverride?.length) return 'Experience';

  switch (true) {
    case categories?.includes('stay-at-home-experiences'):
      return 'At Home Experiences';
    case categories?.includes('collection-vouchers'):
      return 'Multi Choice Collections';
    case categories?.includes('gift-cards'):
      return 'Gift Card';
    case categories?.includes('physical-gifts'):
      return 'Physical gifts';
    default:
      return 'Experience';
  }
};

export function roundDistance(num: number, isUnitShort?: boolean) {
  const distance = round(num, 0);

  const unitValue = isUnitShort ? 'mi' : 'mile';
  const unitPlural = isUnitShort ? '' : distance > 1 ? 's' : '';
  const unit = `${unitValue}${unitPlural}`;

  if (distance < 1) {
    return `< 1 ${unit}`;
  }

  if (distance > 999) {
    return `999+ ${unit}`;
  }

  return `${distance} ${unit}`;
}
