import { SVGProps } from 'react';

type IconStarProps = {
  className?: string;
  type?: 'full' | 'half' | 'empty';
  shadedcolor?: string;
  emptycolor?: string;
  bordercolor?: string;
} & SVGProps<SVGSVGElement>;

export const IconStarFull = ({
  width = 20,
  height = 20,
  className,
  shadedcolor,
}: IconStarProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="star"
  >
    <path
      data-value="filled"
      d="M9.049,2.927c0.3,-0.921,1.603,-0.921,1.902,0l1.07,3.292a1,1,0,0,0,0.95,0.69h3.462c0.969,0,1.371,1.24,0.588,1.81l-2.8,2.034a1,1,0,0,0,-0.364,1.118l1.07,3.292c0.3,0.921,-0.755,1.688,-1.54,1.118l-2.8,-2.034a1,1,0,0,0,-1.175,0l-2.8,2.034c-0.784,0.57,-1.838,-0.197,-1.539,-1.118l1.07,-3.292a1,1,0,0,0,-0.364,-1.118l-2.799,-2.033c-0.783,-0.57,-0.38,-1.81,0.588,-1.81h3.461a1,1,0,0,0,0.951,-0.69l1.07,-3.292l-0.001,-0.001z"
      fill={shadedcolor}
    />
  </svg>
);

export const IconStarHalf = ({
  width = 20,
  height = 20,
  className,
  shadedcolor,
  emptycolor,
}: IconStarProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="half-star"
  >
    <path
      fill={shadedcolor}
      fillRule="evenodd"
      clipRule="evenodd"
      data-value="filled"
      d="M10,2.236c-0.4,0,-0.801,0.23,-0.951,0.691l0.001,0.001l-1.07,3.292a1,1,0,0,1,-0.951,0.69h-3.461c-0.968,0,-1.371,1.24,-0.588,1.81l2.799,2.033a1,1,0,0,1,0.364,1.118l-1.07,3.292c-0.299,0.921,0.755,1.688,1.539,1.118l2.8,-2.034a1,1,0,0,1,0.587,-0.19h0.001v-11.821z"
    />
    <path
      fill={emptycolor}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10,2.24c0.4,0,0.801,0.23,0.951,0.69l-0.001,0.002l1.07,3.292a1,1,0,0,0,0.951,0.69h3.461c0.968,0,1.371,1.24,0.588,1.81l-2.799,2.033a1,1,0,0,0,-0.364,1.118l1.07,3.292c0.299,0.92,-0.755,1.688,-1.539,1.118l-2.8,-2.034a1,1,0,0,0,-0.587,-0.191h-0.001v-11.82z"
      data-value="empty"
    />
  </svg>
);

export const IconStarEmpty = ({
  width,
  height,
  className,
  emptycolor,
  bordercolor,
}: IconStarProps) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="empty-star"
  >
    <path
      fill={emptycolor}
      strokeWidth={bordercolor ? 1 : 0}
      stroke={bordercolor ?? 'none'}
      data-value="empty"
      d="M9.049,2.927c0.3,-0.921,1.603,-0.921,1.902,0l1.07,3.292a1,1,0,0,0,0.95,0.69h3.462c0.969,0,1.371,1.24,0.588,1.81l-2.8,2.034a1,1,0,0,0,-0.364,1.118l1.07,3.292c0.3,0.921,-0.755,1.688,-1.54,1.118l-2.8,-2.034a1,1,0,0,0,-1.175,0l-2.8,2.034c-0.784,0.57,-1.838,-0.197,-1.539,-1.118l1.07,-3.292a1,1,0,0,0,-0.364,-1.118l-2.799,-2.033c-0.783,-0.57,-0.38,-1.81,0.588,-1.81h3.461a1,1,0,0,0,0.951,-0.69l1.07,-3.292l-0.001,-0.001z"
    />
  </svg>
);

/* @deprecated, use IconStarEmpty/Half/Full */
export const IconStar = ({
  className,
  width = 20,
  height = 20,
  type = 'full',
  shadedcolor = '#FED141',
  emptycolor = '#D1D5DB',
  bordercolor,
}: IconStarProps) => {
  const sharedProps = {
    width,
    height,
    className,
  } as const;

  switch (type) {
    case 'full':
      return <IconStarFull {...sharedProps} shadedcolor={shadedcolor} />;
    case 'half':
      return <IconStarHalf {...sharedProps} shadedcolor={shadedcolor} emptycolor={emptycolor} />;
    case 'empty':
      return <IconStarEmpty {...sharedProps} emptycolor={emptycolor} bordercolor={bordercolor} />;
    default:
      return <IconStarFull {...sharedProps} shadedcolor={shadedcolor} />;
  }
};
