import type { HTMLAttributes } from 'react';
import { cn } from '@virginexperiencedays/tailwind';

import { PromotionBadge, type Props } from '.';

/**
 * NOTE: BlackFridayBadge is styled here, in a separate file, because we want to
 * import it with next/dynamic (to avoid bringing in the image for every Product
 * card, regardless of whether they actually use the banner).
 *
 * However, styling the icon *after* being dynamically imported doesn't seem to
 * work 100% -- positioning applies, but width and height are reverted to
 * BlackFridayBadge's own width and height (which vary on desktop and mobile).
 *
 * To get around this, we simply style the icon here, with a regular hard
 * import, then import this component instead with next/dynamic.
 *
 * The styles for this card are generic to all cards in the current designs
 */
export const BlackFridayBadge = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement> & { type: Props['type'] }) => {
  return (
    <PromotionBadge
      className={cn('b-2 r-2 absolute w-auto max-w-[100px]', className)}
      type="black-friday"
      {...props}
    />
  );
};
