import { useEffect } from 'react';
import { B2B_ROUTE_STORAGE_KEY, VALID_B2B_ROUTES } from './constants';
import { getVisitedRoutesFromStorage } from './getVisitedRoutesFromStorage';

/**
 * A hook that tracks if a user visits a valid b2b route.
 * * Category/Custom page usage: Add the current page slug to the visited routes array
 * * PDP usage: Check if any of the valid routes have been visited
 * @param {string} [slug] - slug of the current page
 * @returns visitedValidRoutes function that returns boolean
 *
 */
export const useValidB2bRoute = (slug?: string) => {
  const sessionStorage = typeof window !== 'undefined' && window.sessionStorage;

  // Category page usage
  useEffect(() => {
    if (!sessionStorage || !slug) return;

    if (VALID_B2B_ROUTES.includes(slug)) {
      const visitedRoutes = getVisitedRoutesFromStorage();

      // Adding slug to the array, making sure it's unique
      const updatedArray = [...new Set([...visitedRoutes, slug])];

      sessionStorage.setItem(B2B_ROUTE_STORAGE_KEY, JSON.stringify(updatedArray));
    }
  }, [sessionStorage, slug]);

  // PDP usage
  const visitedValidRoutes = () => {
    if (!sessionStorage) return false;

    const visitedRoutes = getVisitedRoutesFromStorage();

    return visitedRoutes?.length > 0;
  };

  return { visitedValidRoutes };
};
