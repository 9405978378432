import { cn } from '@virginexperiencedays/tailwind';

import { roundStarRating } from '../utils/roundAverageRating';
import { IconStar } from '../icons/IconStar';

export type StarRatingProps = {
  className?: string;
  rating?: number;
  shouldRoundUp?: boolean;
  minDisplay?: number | null;
  Icon?: typeof IconStar;
  IconWrapper?: any;
  hasResponsiveGap?: boolean;
  shadedColor?: string;
  emptyColor?: string;
  isShadedBorder?: boolean;
};

const StyledIconWrapper = ({ children, ...rest }) => (
  <div className="leading-0 fill-blue-500 text-blue-500" {...rest}>
    {children}
  </div>
);

export const StarRating = ({
  rating,
  shouldRoundUp,
  minDisplay,
  className,
  Icon = IconStar,
  IconWrapper = StyledIconWrapper,
  shadedColor,
  emptyColor,
  hasResponsiveGap = false,
}: StarRatingProps) => {
  // Round rating to nearest 0.5 to account for half stars
  const roundedRating = roundStarRating(rating, { shouldRoundUp, minDisplay });

  const starRatings = [
    { type: 'empty' },
    { type: 'empty' },
    { type: 'empty' },
    { type: 'empty' },
    { type: 'empty' },
  ];

  for (let i = 0; i < roundedRating; i++) {
    if (roundedRating % 1 === 0 || roundedRating > i + 0.5) {
      starRatings.splice(i, 1, { type: 'full' });
    } else {
      starRatings.splice(i, 1, { type: 'half' });
    }
  }

  return (
    <div
      className={cn('flex items-center', hasResponsiveGap && 'gap-2 lg:gap-1', className)}
      data-testid="star-rating"
      data-rating={rating}
    >
      {[...Array(5)].map((_, i) => (
        <IconWrapper key={i}>
          <Icon
            type={starRatings[i].type as 'full' | 'half' | 'empty'}
            shadedcolor={shadedColor}
            emptycolor={emptyColor}
          />
        </IconWrapper>
      ))}
    </div>
  );
};
