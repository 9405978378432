import { CSSProperties } from 'react';

import { cn } from '@virginexperiencedays/tailwind';

import { Image } from '../layout/Image';

export type PromotionBadgeType =
  | 'black-friday'
  | 'black-friday-best-deals-ever'
  | 'cyber-monday'
  | 'christmas'
  | 'easter'
  | 'mothers-day'
  | 'fathers-day'
  | 'valentines';

export type PromotionBadgeProps = {
  /** Image source URL */
  src: string;
  /** Image alt text */
  alt?: string;
  /** Image width */
  width: number;
  /** Image height */
  height: number;
};

export type Props = {
  type: PromotionBadgeType;
  className?: string;
  fill?: boolean;
  homeLink?: string;
  priority?: boolean;
  style?: CSSProperties;
};

export const PROMOTION_BADGE_MAP: Record<PromotionBadgeType, PromotionBadgeProps> = {
  'black-friday': {
    src: '/assets/icons/promotion/BF23.svg',
    alt: 'Black Friday',
    width: 100,
    height: 64,
  },
  'black-friday-best-deals-ever': {
    src: '/assets/icons/promotion/BF23-best-deals-ever.svg',
    alt: 'Black Friday',
    width: 220,
    height: 177,
  },
  'cyber-monday': {
    src: '/assets/icons/promotion/CM23.svg',
    alt: 'Cyber Monday',
    width: 100,
    height: 64,
  },
  christmas: {
    src: '/assets/icons/promotion/Christmas23.svg',
    alt: 'Christmas',
    width: 80,
    height: 65,
  },
  easter: {
    src: null,
    alt: null,
    width: 0,
    height: 0,
  },
  'mothers-day': {
    src: null,
    alt: null,
    width: 0,
    height: 0,
  },
  'fathers-day': {
    src: null,
    alt: null,
    width: 0,
    height: 0,
  },
  valentines: {
    src: '/assets/icons/promotion/Valentines24.svg',
    alt: 'Valentines',
    width: 80,
    height: 69,
  },
};

export const PromotionBadge = ({
  type,
  className,
  fill,
  homeLink = '',
  priority,
  style,
}: Props) => {
  if (!type || !PROMOTION_BADGE_MAP?.[type]) return null;

  const { src, alt, width, height } = PROMOTION_BADGE_MAP[type];

  return (
    <div
      data-testid={`product-card-promo-badge-${type}`}
      className={cn(styles.base, styles?.[type] || '', className)}
      style={style}
    >
      <Image
        src={`${homeLink}${src}`}
        alt={alt ?? type}
        width={width}
        height={height}
        priority={priority}
        fill={fill}
      />
    </div>
  );
};

const styles = {
  base: 'absolute w-auto max-w-[100px] bottom-2 right-2 text-right',
  christmas: 'right-0',
};
