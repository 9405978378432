export function roundPrice(price: number): string {
  // It returns price with 2 decimals
  const returnValue = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 2,
  }).format(price);
  if (returnValue.endsWith('.00')) {
    return returnValue.replace('.00', '');
  }
  return returnValue;
}

// https://stackoverflow.com/a/48850944/10933675 - Round half away from zero ('commercial' rounding)
export function round(num: number, decimalPlaces: number): number {
  const p = Math.pow(10, decimalPlaces);
  const e = Number.EPSILON * num * p;
  return Math.round(num * p + e) / p;
}
