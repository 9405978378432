import type { HTMLAttributes } from 'react';
import { cn } from '@virginexperiencedays/tailwind';

import { BlackFridayBadge as PromotionBadge } from './BlackFridayBadge';

/**
 * NOTE: ChristmasBadge is styled here, in a separate file, because we want to
 * import it with next/dynamic (to avoid bringing in the image for every Product
 * card, regardless of whether they actually use the banner).
 *
 * However, styling the icon *after* being dynamically imported doesn't seem to
 * work 100% -- positioning applies, but width and height are reverted to
 * ChristmasBadge's own width and height (which vary on desktop and mobile).
 *
 * To get around this, we simply style the icon here, with a regular hard
 * import, then import this component instead with next/dynamic.
 *
 * The styles for this card are generic to all cards in the current designs
 */
export const ChristmasBadge = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return <PromotionBadge className={cn('r-0', className)} type="christmas" {...props} />;
};
