import { roundPrice } from './roundPrice';

export const calculateSavingsPercentage = (
  currentPrice: number | string,
  pastPrice: number | string
): number => {
  // if currentPrice is a string remove any non-numeric characters
  const currentPriceNumber =
    typeof currentPrice === 'string'
      ? Number(currentPrice.replace(/[^0-9.-]+/g, ''))
      : currentPrice;

  // if pastPrice is a string remove any non-numeric characters
  const pastPriceNumber =
    typeof pastPrice === 'string' ? Number(pastPrice.replace(/[^0-9.-]+/g, '')) : pastPrice;

  return Math.floor(((pastPriceNumber - currentPriceNumber) / pastPriceNumber) * 100);
};

export const handlePriceFormat = (
  price: string | number,
  formatNumberFunc: (n: number) => string
): string | null => {
  if (typeof price === 'string') return price;

  if (typeof price === 'number' && price > 0) {
    return formatNumberFunc?.(price) ?? null;
  }

  return null;
};

export const getProductCardPrices = ({ displayPrice, rrp, percentOff }) => {
  rrp = displayPrice !== rrp ? rrp : null;

  const currentPrice = handlePriceFormat(displayPrice, roundPrice);
  const pastPrice = handlePriceFormat(rrp, roundPrice);
  const roundedPercentOff =
    typeof percentOff === 'number'
      ? Math.round(percentOff)
      : calculateSavingsPercentage(displayPrice, rrp);

  return { currentPrice, pastPrice, roundedPercentOff };
};
