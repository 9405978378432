import type { IconProps as Props } from './types';

export const IconChristmas = ({ className, dataTestId = 'icon-christmas', ...rest }: Props) => {
  return (
    <svg
      className={className}
      data-testid={dataTestId}
      {...rest}
      width="130"
      height="85"
      viewBox="0 0 130 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66514 32.2331C1.64654 32.5252 1.0573 33.5867 1.34904 34.6042L15.2154 82.9617C15.5071 83.9791 16.5693 84.5671 17.5879 84.2751L114.263 56.554C114.927 56.3635 115.438 55.8308 115.6 55.1595L122.377 27.0494C122.535 26.3946 122.337 25.7053 121.856 25.2337L101.213 4.98618C100.72 4.50264 100.004 4.32152 99.34 4.51203L2.66514 32.2331ZM111.962 32.2928C113.49 31.8547 114.374 30.2624 113.937 28.7362C113.499 27.2101 111.906 26.3281 110.378 26.7662C108.85 27.2044 107.966 28.7967 108.404 30.3228C108.841 31.8489 110.435 32.7309 111.962 32.2928Z"
        fill="#E10A0A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.19338 34.0753L17.0597 82.4329L113.735 54.7118L120.512 26.6017L99.8683 6.35422L3.19338 34.0753ZM115.781 28.2074C116.51 30.7509 115.037 33.4048 112.491 34.135C109.944 34.8652 107.289 33.3952 106.559 30.8517C105.83 28.3081 107.303 25.6542 109.849 24.924C112.396 24.1938 115.052 25.6638 115.781 28.2074ZM1.34904 34.6042C1.0573 33.5867 1.64654 32.5252 2.66514 32.2331L99.34 4.51203C100.004 4.32152 100.72 4.50264 101.213 4.98618L121.856 25.2337C122.337 25.7053 122.535 26.3946 122.377 27.0494L115.6 55.1595C115.438 55.8308 114.927 56.3635 114.263 56.554L17.5879 84.2751C16.5693 84.5671 15.5071 83.9791 15.2154 82.9617L1.34904 34.6042ZM113.937 28.7362C114.374 30.2624 113.49 31.8547 111.962 32.2928C110.435 32.7309 108.841 31.8489 108.404 30.3228C107.966 28.7967 108.85 27.2044 110.378 26.7662C111.906 26.3281 113.499 27.2101 113.937 28.7362Z"
        fill="#A80C0C"
      />
      <path
        d="M87.5964 55.2629L81.162 32.8235L86.5919 31.2665L91.7633 49.3013L101.109 46.6213L102.372 51.0259L87.5964 55.2629Z"
        fill="white"
      />
      <path
        d="M68.6246 60.703L62.7889 62.3764L63.9344 37.7635L71.1633 35.6907L85.1776 55.9565L79.3418 57.6299L69.2523 42.3572L69.0768 42.4075L68.6246 60.703ZM65.3135 52.0952L77.5116 48.5975L78.6929 52.7172L66.4948 56.2149L65.3135 52.0952Z"
        fill="white"
      />
      <path
        d="M44.7004 67.5631L38.266 45.1238L53.9304 40.6321L55.1934 45.0367L44.9589 47.9714L46.2784 52.5732L55.7122 49.8681L56.9783 54.2837L47.5446 56.9888L48.8673 61.6015L59.1018 58.6668L60.3648 63.0714L44.7004 67.5631Z"
        fill="white"
      />
      <path
        d="M30.44 71.6523L22.1361 74.0334L15.7017 51.594L23.9946 49.216C26.2836 48.5597 28.3833 48.4438 30.2936 48.8683C32.2092 49.2835 33.8576 50.2021 35.2389 51.6242C36.618 53.039 37.6417 54.9114 38.3098 57.2416C38.9801 59.579 39.1063 61.7167 38.6885 63.6547C38.2781 65.5905 37.3717 67.2457 35.9693 68.6201C34.5647 69.9873 32.7217 70.998 30.44 71.6523ZM26.2402 67.8526L28.9057 67.0883C30.1636 66.7276 31.1669 66.2107 31.9156 65.5375C32.6696 64.8549 33.1314 63.9675 33.301 62.8754C33.4759 61.7739 33.3318 60.416 32.869 58.8017C32.4061 57.1874 31.8107 55.9668 31.0829 55.14C30.353 54.3058 29.4849 53.8037 28.4786 53.6338C27.4774 53.4545 26.3333 53.5493 25.0462 53.9184L22.4574 54.6607L26.2402 67.8526Z"
        fill="white"
      />
      <path
        d="M91.4313 18.3835C91.2814 18.0146 91.0249 17.7675 90.6615 17.6421C90.3011 17.5159 89.8809 17.5216 89.4009 17.6593C89.0635 17.756 88.7887 17.8899 88.5764 18.0608C88.3641 18.2317 88.219 18.4227 88.141 18.6337C88.063 18.8447 88.0546 19.0625 88.1159 19.2871C88.1692 19.473 88.2576 19.6222 88.3811 19.7345C88.5075 19.8461 88.6587 19.9285 88.8345 19.9818C89.0095 20.0323 89.1964 20.0636 89.395 20.0758C89.5937 20.088 89.7909 20.0896 89.9867 20.0807L90.8844 20.0496C91.2443 20.0313 91.599 20.0444 91.9486 20.0888C92.3011 20.1323 92.6301 20.2204 92.9357 20.3529C93.2441 20.4845 93.5123 20.6733 93.7403 20.9192C93.9682 21.1652 94.1376 21.4813 94.2484 21.8678C94.3983 22.3907 94.3966 22.8896 94.2431 23.3644C94.0888 23.8363 93.7906 24.2566 93.3487 24.6255C92.9087 24.9906 92.331 25.2757 91.6154 25.4809C90.9202 25.6803 90.2858 25.7458 89.7122 25.6776C89.1415 25.6086 88.6531 25.4091 88.2468 25.0791C87.8434 24.7483 87.5451 24.2898 87.3519 23.7038L88.9445 23.2471C89.0569 23.5513 89.227 23.7824 89.4549 23.9403C89.6827 24.0982 89.9465 24.1892 90.2462 24.2134C90.5488 24.2366 90.8688 24.1999 91.2062 24.1031C91.5582 24.0022 91.8515 23.8615 92.0862 23.681C92.323 23.4968 92.4899 23.2885 92.5871 23.0563C92.6833 22.8212 92.6963 22.5754 92.6259 22.3189C92.5563 22.0873 92.433 21.9151 92.2558 21.8024C92.0779 21.6868 91.8536 21.6127 91.583 21.5803C91.3145 21.5441 91.0086 21.5312 90.6653 21.5416L89.5737 21.5716C88.7836 21.5938 88.1198 21.4744 87.5822 21.2135C87.0467 20.9488 86.6873 20.4969 86.504 19.8577C86.3532 19.3318 86.3637 18.8305 86.5355 18.3536C86.7101 17.8759 87.0135 17.4572 87.4455 17.0975C87.8767 16.7349 88.405 16.4639 89.0304 16.2846C89.6645 16.1027 90.2517 16.0538 90.7921 16.1378C91.3345 16.2181 91.7983 16.4137 92.1833 16.7247C92.5676 17.0327 92.8361 17.4367 92.989 17.9369L91.4313 18.3835Z"
        fill="white"
      />
      <path
        d="M79.8483 28.7088L78.1204 29.2043L78.707 19.3767L80.7053 18.8037L86.4149 26.8259L84.6871 27.3213L80.2659 20.9106L80.1961 20.9307L79.8483 28.7088ZM78.9015 25.193L83.6138 23.8418L83.9862 25.1405L79.2739 26.4917L78.9015 25.193Z"
        fill="white"
      />
      <path
        d="M64.0235 23.5872L66.0044 23.0191L70.5118 28.7259L70.6165 28.6959L71.4148 21.4677L73.3957 20.8997L75.955 29.8252L74.4017 30.2706L72.6434 24.1387L72.5605 24.1625L71.8417 30.9764L70.6811 31.3092L66.457 25.8985L66.3741 25.9223L68.1361 32.0672L66.5828 32.5126L64.0235 23.5872Z"
        fill="white"
      />
      <path
        d="M54.9255 27.6628L54.5368 26.3074L61.6663 24.263L62.0549 25.6184L59.293 26.4104L61.4637 33.9805L59.8581 34.4409L57.6874 26.8708L54.9255 27.6628Z"
        fill="white"
      />
      <path
        d="M51.527 29.8259C51.3772 29.457 51.1206 29.2098 50.7572 29.0845C50.3968 28.9583 49.9766 28.964 49.4966 29.1017C49.1592 29.1984 48.8844 29.3322 48.6721 29.5032C48.4598 29.6741 48.3147 29.8651 48.2367 30.0761C48.1587 30.2871 48.1503 30.5049 48.2116 30.7294C48.2649 30.9154 48.3533 31.0646 48.4768 31.1769C48.6032 31.2884 48.7544 31.3709 48.9302 31.4242C49.1052 31.4747 49.2921 31.506 49.4907 31.5182C49.6894 31.5304 49.8866 31.532 50.0824 31.523L50.9801 31.492C51.34 31.4737 51.6947 31.4868 52.0443 31.5312C52.3968 31.5747 52.7258 31.6628 53.0314 31.7952C53.3399 31.9269 53.6081 32.1157 53.836 32.3616C54.0639 32.6075 54.2333 32.9237 54.3441 33.3101C54.494 33.8331 54.4923 34.332 54.3388 34.8068C54.1845 35.2787 53.8863 35.699 53.4444 36.0679C53.0044 36.433 52.4267 36.7181 51.7111 36.9233C51.0159 37.1227 50.3815 37.1882 49.8079 37.12C49.2372 37.051 48.7488 36.8515 48.3425 36.5215C47.9391 36.1906 47.6408 35.7322 47.4476 35.1462L49.0402 34.6895C49.1526 34.9937 49.3227 35.2248 49.5506 35.3827C49.7784 35.5406 50.0422 35.6316 50.3419 35.6557C50.6445 35.679 50.9645 35.6423 51.3019 35.5455C51.6539 35.4446 51.9472 35.3039 52.1819 35.1234C52.4187 34.9392 52.5856 34.7309 52.6828 34.4987C52.779 34.2636 52.792 34.0178 52.7216 33.7612C52.652 33.5296 52.5287 33.3575 52.3515 33.2448C52.1736 33.1291 51.9493 33.0551 51.6787 33.0226C51.4102 32.9864 51.1043 32.9735 50.761 32.9839L49.6694 33.014C48.8793 33.0361 48.2155 32.9168 47.6779 32.6559C47.1424 32.3912 46.783 31.9393 46.5997 31.3001C46.4489 30.7742 46.4594 30.2728 46.6312 29.796C46.8058 29.3183 47.1092 28.8995 47.5412 28.5398C47.9724 28.1772 48.5007 27.9063 49.1261 27.7269C49.7602 27.5451 50.3474 27.4962 50.8878 27.5802C51.4302 27.6605 51.894 27.8561 52.279 28.167C52.6633 28.4751 52.9318 28.8791 53.0847 29.3793L51.527 29.8259Z"
        fill="white"
      />
      <path
        d="M43.2314 29.5493L45.7907 38.4747L44.1719 38.9389L41.6126 30.0134L43.2314 29.5493Z"
        fill="white"
      />
      <path
        d="M35.1978 41.5121L32.6385 32.5867L35.9894 31.6258C36.6759 31.429 37.286 31.3829 37.8197 31.4877C38.3564 31.5917 38.8056 31.8245 39.1674 32.1861C39.5313 32.5439 39.7949 33.0076 39.9582 33.5771C40.1223 34.1494 40.1426 34.6813 40.0191 35.1726C39.8976 35.6603 39.6342 36.0864 39.2288 36.451C38.8225 36.8128 38.2762 37.0921 37.5897 37.2889L35.203 37.9733L34.8181 36.631L36.9866 36.0092C37.3881 35.8941 37.7009 35.7446 37.9252 35.5608C38.1487 35.3741 38.2907 35.1557 38.3512 34.9057C38.4138 34.6519 38.3997 34.3667 38.3089 34.05C38.2181 33.7333 38.0781 33.481 37.8889 33.2932C37.6988 33.1024 37.4591 32.9856 37.1698 32.9428C36.8797 32.8971 36.5325 32.9322 36.1281 33.0481L34.6446 33.4735L36.8166 41.048L35.1978 41.5121ZM38.6544 36.1441L42.0263 39.5541L40.2199 40.0721L36.8873 36.6508L38.6544 36.1441Z"
        fill="white"
      />
      <path
        d="M25.0717 44.4157L22.5124 35.4902L24.1311 35.0261L25.2146 38.8046L29.3553 37.6172L28.2718 33.8387L29.895 33.3733L32.4543 42.2988L30.8312 42.7642L29.744 38.9726L25.6033 40.16L26.6905 43.9515L25.0717 44.4157Z"
        fill="white"
      />
      <path
        d="M20.9105 39.2087L19.2787 39.6766C19.1555 39.4227 19.0018 39.2105 18.8176 39.0401C18.6325 38.8667 18.4261 38.7341 18.1982 38.6422C17.9704 38.5504 17.7268 38.5023 17.4675 38.4981C17.2102 38.4901 16.9449 38.5253 16.6714 38.6037C16.1857 38.743 15.7901 38.9885 15.4849 39.3401C15.1788 39.6889 14.9867 40.1276 14.9084 40.6562C14.8294 41.182 14.8861 41.7804 15.0786 42.4516C15.2743 43.1344 15.5455 43.6792 15.8919 44.0861C16.2405 44.4892 16.6351 44.7565 17.0757 44.888C17.5185 45.0158 17.9812 45.0104 18.4641 44.8719C18.7317 44.7952 18.9719 44.6886 19.1846 44.5522C19.3994 44.412 19.5799 44.2454 19.726 44.0526C19.8751 43.8589 19.9845 43.642 20.0542 43.402C20.1267 43.1611 20.1503 42.9028 20.1247 42.6271L21.7591 42.1679C21.8196 42.6096 21.8014 43.0456 21.7044 43.4759C21.6104 43.9053 21.4413 44.3091 21.1971 44.6873C20.9521 45.0625 20.6344 45.3926 20.2439 45.6775C19.8526 45.9595 19.3907 46.1768 18.8584 46.3295C18.0731 46.5547 17.32 46.5741 16.5992 46.3877C15.8783 46.2014 15.2422 45.8163 14.6907 45.2323C14.1392 44.6484 13.726 43.8771 13.4511 42.9183C13.1753 41.9566 13.1184 41.083 13.2804 40.2977C13.4416 39.5094 13.7784 38.8453 14.291 38.3052C14.8035 37.7652 15.4495 37.3835 16.2291 37.1599C16.7265 37.0173 17.209 36.9544 17.6766 36.9713C18.1442 36.9881 18.584 37.0837 18.996 37.258C19.4071 37.4293 19.7762 37.6788 20.103 38.0064C20.432 38.3303 20.7012 38.7311 20.9105 39.2087Z"
        fill="white"
      />
    </svg>
  );
};
