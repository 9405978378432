import { B2B_ROUTE_STORAGE_KEY } from './constants';

export const getVisitedRoutesFromStorage = () => {
  const currentStorageItem = sessionStorage.getItem(B2B_ROUTE_STORAGE_KEY);

  let storageItemJson = [];
  try {
    storageItemJson = JSON.parse(currentStorageItem ?? '[]');
  } catch (error) {
    console.error(`[Error parsing sessionStorage item: ${B2B_ROUTE_STORAGE_KEY}] - ${error}`);
  }

  const array = Array.isArray(storageItemJson) ? storageItemJson : [];

  return array;
};
