import type { ElementType } from 'react';

import { IconBlackFriday } from '../icons/IconBlackFriday';
import { IconChristmas } from '../icons/IconChristmas';
import { IconCyberMonday } from '../icons/IconCyberMonday';
import { IconValentines } from '../icons/IconValentines';

import { BlackFridayBadge } from '../PromotionBadge/BlackFridayBadge';
import { ChristmasBadge } from '../PromotionBadge/ChristmasBadge';
import { CyberMondayBadge } from '../PromotionBadge/CyberMondayBadge';
import { ValentinesBadge } from '../PromotionBadge/ValentinesBadge';

export const PROMOS = {
  black_friday: 'black-friday',
  christmas: 'christmas',
  cyber_monday: 'cyber-monday',
  easter: 'easter',
  mothers_day: 'mothers-day',
  fathers_day: 'fathers-day',
  valentines: 'valentines',
};

// Order of precedence for the PromoBadge is:
//   1. Fathers Day
//   2. Easter
//   3. Mothers Day
//   4. Valentines
//   5. Christmas
//   6. Cyber Monday
//   7. Black Friday
type PromoProps = {
  isBlackFridayProduct?: boolean;
  isChristmasProduct?: boolean;
  isCyberMondayProduct?: boolean;
  isEasterProduct?: boolean;
  isMothersDayProduct?: boolean;
  isFathersDayProduct?: boolean;
  isValentinesProduct?: boolean;
};

/**
 * Returns the appropriate icon component based on the product promotion type.
 * @param {PromoProps} props - The promotional properties of the product.
 * @returns The icon component for the corresponding promotion type, or null if no promotion type matches.
 */
export const ProductPromoIcon = ({
  isChristmasProduct,
  isCyberMondayProduct,
  isBlackFridayProduct,
  isEasterProduct,
  isMothersDayProduct,
  isFathersDayProduct,
  isValentinesProduct,
}: PromoProps): ElementType => {
  switch (true) {
    case isEasterProduct: // falls through
    case isMothersDayProduct: // falls through
    case isFathersDayProduct: // falls through
      return null; // uses tag at the top left instead
    case isValentinesProduct:
      return IconValentines;
    case isChristmasProduct:
      return IconChristmas;
    case isCyberMondayProduct:
      return IconCyberMonday;
    case isBlackFridayProduct:
      return IconBlackFriday;
    default:
      return null;
  }
};

/**
 * Returns the appropriate badge component based on the product's promotional properties.
 * @param {PromoProps} props - The promotional properties of the product.
 * @returns {ElementType} - The badge component.
 */
export const ProductPromoBadge = ({
  isChristmasProduct,
  isCyberMondayProduct,
  isBlackFridayProduct,
  isEasterProduct,
  isValentinesProduct,
  isMothersDayProduct,
  isFathersDayProduct,
}: PromoProps): ElementType => {
  switch (true) {
    case isEasterProduct: // falls through
    case isMothersDayProduct: // falls through
    case isFathersDayProduct: // falls through
      return null; // uses tag at the top left instead
    case isValentinesProduct:
      return ValentinesBadge;
    case isChristmasProduct:
      return ChristmasBadge;
    case isCyberMondayProduct:
      return CyberMondayBadge;
    case isBlackFridayProduct:
      return BlackFridayBadge;
    default:
      return null;
  }
};

/**
 * Determines the promo tag for a product based on the provided flags.
 * @param {PromoProps} promoProps - The flags indicating the type of promotion.
 * @returns {string | null} - The promo tag for the product.
 */
export const productPromoTag = ({
  isChristmasProduct,
  isCyberMondayProduct,
  isBlackFridayProduct,
  isEasterProduct,
  isMothersDayProduct,
  isFathersDayProduct,
  isValentinesProduct,
}: PromoProps) => {
  switch (true) {
    case isFathersDayProduct:
      return PROMOS.fathers_day;
    case isEasterProduct:
      return PROMOS.easter;
    case isMothersDayProduct:
      return PROMOS.mothers_day;
    case isValentinesProduct:
      return PROMOS.valentines;
    case isChristmasProduct:
      return PROMOS.christmas;
    case isCyberMondayProduct:
      return PROMOS.cyber_monday;
    case isBlackFridayProduct:
      return PROMOS.black_friday;
    default:
      return null;
  }
};
